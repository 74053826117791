import React, { createRef, useState } from "react"
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Button,
  TextField,
} from '@mui/material';

import { doPost } from "../../../actions/_methods";
import { API_RESET_PASSWORD } from "../../../const/api_paths";

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: '10px',
    width: '810px',
  },
  buttons_section: {
    marginTop: '20px',
  },
  links: {
    color: '#1976d2',
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
    }
  }
}));


export default ({ token }) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // const [email, setEmail] = useState('')
  // const [password, setPassword] = useState('')
  // const [confirm_password, setConfirmPassword] = useState('')

  const email_ref = createRef();
  const password_ref = createRef();
  const confirm_password_ref = createRef();

  const handleSubmit = (e) => {
    let data = {
      token: token,
      email: email_ref.current.value,
      password: password_ref.current.value,
      confirm_password: confirm_password_ref.current.value
    }

    doPost(`${API_RESET_PASSWORD}`, data, {
      success: onSuccess,
      general: onSuccess,
      error: onError,
    });
  }

  const onSuccess = (res) => {
    alert(res['alerts'])
    window.location = "/"
  }

  const onError = (res) => {
    enqueueSnackbar(res['errors'], { variant: 'error' });
  }

  const backToHomepage = () => {
    window.location = "/"
  }

  const emailForm = (
    <TextField
      margin="normal"
      required
      fullWidth
      id="forgot_password_email"
      label="Email Address"
      name="email"
      autoFocus
      variant="outlined"
      inputRef={email_ref}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSubmit()
        }
      }}
    />
  )
  const passwordForm = (
    <TextField
      type="password"
      margin="normal"
      required
      fullWidth
      id="forgot_password_email"
      label="Password"
      name="password"
      autoFocus
      variant="outlined"
      inputRef={password_ref}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSubmit()
        }
      }}
    />
  )
  const confirmPasswordForm = (
    <TextField
      type="password"
      margin="normal"
      required
      fullWidth
      id="forgot_password_email"
      label="Confirm Password"
      name="confirm_password"
      autoFocus
      variant="outlined"
      inputRef={confirm_password_ref}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSubmit()
        }
      }}
    />
  )

  const sendLinkButton = (
    <Button
      fullWidth
      variant="contained"
      size="large"
      color="primary"
      onClick={handleSubmit}
    >
      Submit
    </Button>
  )

  return (
    <Card className={classes.box}>
      <CardHeader title="Reset Password" />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& .MuiTextField-root': { width: '25ch' },
          }}
        >
          {emailForm}
          {passwordForm}
          {confirmPasswordForm}
        </Box>
        <Box className={classes.buttons_section}>{sendLinkButton}</Box>
        <Box className={classes.buttons_section}>
          <a href="/" className={classes.links}>Back to Homepage</a>
        </Box>
      </CardContent>
    </Card>
  )
}